<template>
    <div class="w-full">
      <base-card v-show="showFilterPane">
        <ContactFilters @change-contact-filters="setContactFilters" @set-changed-contact-filters="setChangedContactFilters"></ContactFilters>
      </base-card>
      <base-card v-show="showBulkOperationsPane">
        <ContactBulkOperations 
          :selectedContacts="selectedContacts"
          :userPermissions="userPermissions"
          :filters="fetchFilter"
          @refetch-contacts="loadContacts"
          @clear-selected-contacts="clearSelectedContacts"
          @reset-select-all="resetSelectAll">
        </ContactBulkOperations>
      </base-card>
      <base-card>
        <!-- <div v-if="selectedContactId" class="grid grid-cols-2 gap-2">
          <div class="flex flex-col">
            <div class="flex flex-col text-sm text-gray-900" :class="[{'h-[60vh]': showBulkOperationsPane && showFilterPane}, {'h-[65vh]': !showBulkOperationsPane && showFilterPane}, {'h-[85vh]': !showFilterPane}, {'h-[90vh]': !showFilterPane && !showBulkOperationsPane}]">
              <base-card class="flex flex-row justify-between w-full mb-0">
                <div class="px-3 h-full flex flex-row justify-start items-center align-middle text-center">
                  <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
                  <p class="align-middle">
                    <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select All | </span>
                    <span v-else class="pl-1 align-middle">Deselect All | </span>
                    <span class="align-middle"> | </span>
                    <span class="hover:text-accent-primary hover:cursor-pointer align-middle" @click="resetSelectAllClearSelectedContacts">Clear All</span>
                  </p>
                </div>
                <div class="flex flex-row items-center justify-start h-full">
                  <div class="flex flex-row justify-start items-center">
                    <div class="w-36">
                      <base-filter-horizontal>
                        <template v-slot:label>
                          Pg Size
                        </template>
                        <template v-slot:widget>
                          <base-list-box-horizontal
                            class="w-full rounded-r-md text-xs"
                            placeholder="Select"
                            :options="pageSizeOptions"
                            :modelValue="pageSizeFilter.contactPageSizeFilter"
                            :multiple="false"
                            @update:modelValue="setContactPageSizeFilter"
                          />
                        </template>
                      </base-filter-horizontal>
                    </div>
                    <div class="flex flex-row items-center ml-3 ">
                      <div class="flex items-center">
                        <span class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="goToFirst">&lt;&lt;</span>
                        <span class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="getPrev">&lt;</span>
                        <div class="flex flex-row">
                          Pg <span><JumpToPage class="mx-1" :current-page="page" :max-page="totalPages" @fetch-page="jumpToPage"/></span>of {{ totalPages }}
                        </div>
                        <span class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="getNext">></span>
                        <span class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="goToLast">>></span>
                      </div>
                    </div>
                    <div class="flex flex-row items-center">
                      <div v-if="isLoading" role="status" class="px-3">
                        <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="white"/>
                          <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="#2E9CCA"/>
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                      <div  v-else class="px-3">
                        <svg @click="loadContacts(fetchFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke-width="1.5" stroke="#25274D" class="mx-1 w-6 h-6 hover:cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round"
                                d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                                />
                        </svg>
                      </div>
                    </div>
                  </div>
                  <div class="flex flex-row items-center align-middle justify-end">
                    <div>
                      {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }}  Contacts
                    </div>

                </div>
              </div>
              </base-card>
              <div v-if="contactResults.length > 0" class="w-full h-full overflow-y-auto">
                <ContactCard
                  @add-remove-selected-contacts="addRemoveSelectedContacts"
                  @add-selected-contact="addSelectedContact"
                  @remove-selected-contact="removeSelectedContact"
                  @refetch-contacts="loadContacts(fetchFilter, true)"
                  @reset-changed-contact-filters="resetSelectedContactsOnChange"
                  @reset-cleared-selected-contacts="resetClearedSelectedContacts"
                  @reset-cleared-selected-contacts-on-page="resetClearedSelectedContactsOnPage"
                  @select-contact="selectContactRow"
                  :selectedContactId="selectedContactId"
                  :selectAll="selectAll"
                  :selectAllOnPage="selectAllOnPage"
                  :selectedContactsOnPageMap="selectedContactsOnPageMap"
                  :key="contact.id"
                  :contact="contact"
                  :clearedSelectedContacts="clearedSelectedContacts"
                  :clearedSelectedContactsOnPage="clearedSelectedContactsOnPage"
                  :changedContactFilters="changedContactFilters"
                  :permissions="userPermissions"
                  v-for="contact in contactResults"
                />
              </div>
            </div>
          </div>
          <div class="flex flex-col">
            <div class="flex flex-col text-sm text-gray-900" :class="[{'h-[60vh]': showBulkOperationsPane && showFilterPane}, {'h-[65vh]': !showBulkOperationsPane && showFilterPane}, {'h-[85vh]': !showFilterPane}, {'h-[92vh]': !showFilterPane && !showBulkOperationsPane}]">
              <base-card class="flex flex-row justify-between w-full mb-0">
                <ContactDetailActions
                  :detailType="detailType"
                  @deselect-contact="deselectAllContactRows"
                  @set-detail-type="setDetailType"
                />
              </base-card>
              <div v-if="selectedContact" class="w-full h-full overflow-y-auto">
                  <div v-if="selectedContact.company_relationships.length > 0" class="h-full overflow-auto relative">
                    <div v-if="detailType=='contact_view'">
                      <base-table class="w-full mt-2 rounded-t-md">
                        <template #table-header>
                          <base-table-header :key="header.id" :header="header" v-for="header in detailHeadersList"> {{
                              header
                            }}
                          </base-table-header>
                        </template>
                        <template #table-body>
                          <ContactDetailRow
                            :companyRelationship="companyRelationship"
                            :key="companyRelationship.id"
                            v-for="companyRelationship in selectedContact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == true)"
                          />
                          <ContactDetailRow
                            :companyRelationship="companyRelationship"
                            :key="companyRelationship.id"
                            v-for="companyRelationship in selectedContact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == false)"
                          />
                        </template>
                      </base-table>
                    </div>
                    <div v-if="detailType=='company_view'">
                      <ContactDetailCard
                        :companyRelationship="companyRelationship"
                        :key="companyRelationship.id"
                        v-for="companyRelationship in selectedContact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == true)"
                      />
                      <ContactDetailCard
                        :companyRelationship="companyRelationship"
                        :key="companyRelationship.id"
                        v-for="companyRelationship in selectedContact.company_relationships.filter(companyRelationship => companyRelationship.is_primary_contact == false)"
                      />
                    </div>
                  </div>
                  <div v-else>
                    No Company Relationships
                  </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- <div v-else> -->
        <div>
          <div class="flex flex-col text-sm text-gray-900" :class="[{'h-[60vh]': showBulkOperationsPane && showFilterPane}, {'h-[65vh]': !showBulkOperationsPane && showFilterPane}, {'h-[85vh]': !showFilterPane}, {'h-[92vh]': !showFilterPane && !showBulkOperationsPane}]">
            <div class="flex flex-col justify-start lg:grid lg:grid-cols-8 w-full mb-2">
              <!-- <div class="lg:col-span-3 px-3 h-full flex flex-row justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none items-center align-middle text-center">
                <input type="checkbox" id="checkbox" class="align-middle" :checked="selectAllOnPage" @change="toggleSelectAllOnPage"/>
                <p class="align-middle">
                  <span v-if="!selectAllOnPage" class="pl-1 align-middle">Select All On Page | </span>
                  <span v-else class="pl-1 align-middle">Deselect All On Page | </span>
                  <span
                    class="pl-1 align-middle text-accent-primary hover:text-dark-primary hover:cursor-pointer"
                    @click="showModal"
                  >{{numSelectedContacts}} Selected
                  </span>
                  <span class="align-middle"> | </span>
                  <span class="hover:text-accent-primary hover:cursor-pointer align-middle" @click="resetSelectAllClearSelectedContacts">Clear All</span>
                  <span class="align-middle"> (</span>
                  <span class="align-middle">{{ numSelectedContactsOnPage }} On Pg</span>
                  <span class="align-middle"> | </span> 
                  <span class="align-middle">{{ numSelectedContactsOffPage }} Off Pg</span>
                  <span class="align-middle">) </span>
                  <base-modal-small 
                    v-if="isModalVisible"
                    @close="closeModal"
                  >
                  <template v-slot:header>
                    Selected Contacts
                  </template>
                  <template v-slot:body>
                    <SelectedContactsTable :selectedContacts="selectedContacts" />
                  </template>
                  </base-modal-small>
                </p>
              </div> -->
              <div class="lg:col-span-4 flex flex-row items-center justify-start py-2 border-b border-light-primary lg:py-0 lg:border-none lg:grid lg:grid-cols-3 h-full px-2">
                <div class="flex flex-row justify-center items-center lg:col-span-2 lg:justify-end">
                  <div class="w-36">
                    <base-filter-horizontal>
                      <template v-slot:label>
                        Pg Size
                      </template>
                      <template v-slot:widget>
                        <base-list-box-horizontal
                          class="w-full rounded-r-md text-xs"
                          placeholder="Select"
                          :options="pageSizeOptions"
                          :modelValue="pageSizeFilter.contactPageSizeFilter"
                          :multiple="false"
                          @update:modelValue="setContactPageSizeFilter"
                        />
                      </template>
                    </base-filter-horizontal>
                  </div>
                  <div class="flex flex-row items-center ml-3">
                    <div class="flex items-center">
                      <span class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="goToFirst">&lt;&lt;</span>
                      <span class="mr-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="getPrev">&lt;</span>
                      <div class="flex flex-row">
                        Page <span><JumpToPage class="mx-1" :current-page="page" :max-page="totalPages" @fetch-page="jumpToPage"/></span>of {{ totalPages }}
                      </div>
                      <span class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="getNext">></span>
                      <span class="ml-1.5 hover:text-accent-primary cursor-pointer text-lg underline" @click="goToLast">>></span>
                    </div>
                  </div>
                  <div class="flex flex-row items-center">
                    <div v-if="isLoading" role="status" class="pl-3">
                      <svg aria-hidden="true" class="mx-1 w-6 h-6 text-gray-200 animate-spin dark:text-gray-600"
                          viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="white"/>
                        <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="#2E9CCA"/>
                      </svg>
                      <span class="sr-only">Loading...</span>
                    </div>
                    <div  v-else class="pl-3">
                      <svg @click="loadContacts(fetchFilter, true)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                          stroke-width="1.5" stroke="#25274D" class="mx-1 w-6 h-6 hover:cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round"
                              d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                              />
                      </svg>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row items-center align-middle lg:ml-3 lg:col-span-1 lg:justify-start">
                  <div>
                    {{ viewingFromNbr }}-{{ viewingToNbr }} | {{ count }}  Total Contacts
                  </div>
                  <div class="flex space-x-3 items-center" ref="csvElement">
                    <base-button  v-if="userPermissions.isAccountManager || userPermissions.isDev || userPermissions.isManagement" @click="getCSV" @click.right.prevent="getCSVWithCurrentFields" class="underline ml-2">⬇</base-button>
                    <base-button @click="showContactForm" class=" whitespace-nowrap">Add Contact</base-button>
                  </div>
                <base-popover-hover 
                class="max-w-fit"
                v-if="isPopoverVisible"
                :xPosition="xPosition"
                :yPosition="yPosition"
              >
                <template v-slot:title>
                </template>
                <template v-slot:body>
                  <span class="">{{ exportCSVText }}</span>
                </template>
              </base-popover-hover>
                </div>
              </div>
            </div>
            <div v-if="contactResults.length > 0" class="h-full overflow-auto relative">
              <ContactCard
                @add-remove-selected-contacts="addRemoveSelectedContacts"
                @add-selected-contact="addSelectedContact"
                @remove-selected-contact="removeSelectedContact"
                @refetch-contacts="loadContacts(fetchFilter, true)"
                @reset-changed-contact-filters="resetSelectedContactsOnChange"
                @reset-cleared-selected-contacts="resetClearedSelectedContacts"
                @reset-cleared-selected-contacts-on-page="resetClearedSelectedContactsOnPage"
                @select-contact="selectContactRow"
                :selectedContactId="selectedContactId"
                :selectAll="selectAll"
                :selectAllOnPage="selectAllOnPage"
                :selectedContactsOnPageMap="selectedContactsOnPageMap"
                :key="contact.id"
                :contact="contact"
                :clearedSelectedContacts="clearedSelectedContacts"
                :clearedSelectedContactsOnPage="clearedSelectedContactsOnPage"
                :changedContactFilters="changedContactFilters"
                :permissions="userPermissions"
                v-for="contact in contactResults"
              />
            </div>
            <base-modal :is-form-visible="isNewContactFormVisible" @close="closeContactForm">
              <template #header>
                <h2 class="text-lg">Contact Form</h2>
              </template>
              <template #body>
                <NewContactForm
                  @close-form="closeContactForm"
                  @refetch-contacts="loadContacts(fetchFilter, true)"
                />
              </template>
            </base-modal>
          </div>
        </div>
      </base-card>
    </div>
  </template>
  
  <script>
  export default {
    name: 'aerocle-contacts',
    data: () => ({
    }),
    methods: {
    }
  }
  </script>
  
  <script setup>
  import {ref, reactive, shallowReactive, computed, watch} from 'vue';
  import {useStore} from 'vuex';
  import ContactFilters from './ContactFilters.vue';
  import ContactBulkOperations from "./ContactBulkOperations";
  import ContactRow from './ContactRow.vue';
  import ContactCard from './ContactCard.vue';
  import ContactDetailActions from './ContactDetailActions.vue';
  import ContactDetailCard from './ContactDetailCard.vue';
  import ContactDetailRow from './ContactDetailRow.vue';
  import CreateContact from './CreateContact.vue';
  import BaseCard from "@/components/BaseCard";
  import SelectedContactsTable from './SelectedContactsTable.vue';
  import {getContacts} from '@/services/Contact.js';
  import JumpToPage from '@/components/JumpToPage.vue';
  import AddMultipleContacts from './AddMultipleContacts.vue';
  import { exportToCSV } from '@/services/Contact.js';
  import NewContactForm from '../NewContactForm.vue';

  
  const store = useStore()
  
  const isLoading = ref(false);
  
  const tableHeadersList = [
    // ' ',
    'ID',
    'First Name',
    'Last Name',
    'ZI',
    'Personal Phone',
    'Personal Email',
    'LinkedIn',
    'Notes',
    'Company Summary',
    'More'
  ];

  const detailHeadersList = [
    '',
    'Company',
    'Title',
    'Email',
    'Phone',
    'Is Primary'

  ]


  const deselectAllContactRows = () => {
    selectedContactId.value = null
  }

  const isNewContactFormVisible = ref(false)

  function showContactForm() {
    isNewContactFormVisible.value = true
  }

  function closeContactForm() {
    isNewContactFormVisible.value = false
  }
  

  // const tableHeadersList = computed(() => {
  //   const headers = ['', 'ID', ...selectedPageConfig.value.cells.map(cell => cell.contact_cell.column_header)]
  //   // console.log('headers', headers)
  //   return headers
  // })

  const contactResults = ref([]);
  const next = ref(null);
  const prev = ref(null);
  const count = ref(null);
  const page = ref(null);
  const pageSize = ref(null);
  const contactFilters = reactive({
    contactIdFilter: null,
    contactFirstNameFilter: null,
    contactLastNameFilter: null,
    contactFullNameFilter: null,
    contactLinkedInFilter: null,
    contactEmailFilter: null,
    contactPhoneFilter: null,
    contactTitleFilter: null,
    contactPersonalNotesFilter: null,
    contactContactNotesFilter: null,
    contactAllNotesFilter: null,
    contactCompanyNameFilter: null,
    contactCompanyIdFilter: null,
    contactCompanyWebsiteUrlFilter: null,
    contactCompanyAddressFilter: null,
    contactCompanyCampaignFilter: null,
    contactCompanyMinRevenueFilter: null,
    contactCompanyMaxRevenueFilter: null,
    contactCompanyMinHeadcountFilter: null,
    contactCompanyMaxHeadcountFilter: null,
    contactCompanyMinRevenueZiCombinedFilter: null,
    contactCompanyMaxRevenueZiCombinedFilter: null,
    contactCompanyOutreachNotesFilter: null,
    contactCompanyAccountManagerResearchNotesFilter: null,
  });

  const pageSizeFilter = reactive({
    contactPageSizeFilter: 50
  })

  const showCreateMultipleContactsForm = ref(false)
  function openCreateMultipleContactsForm(){
    showCreateMultipleContactsForm.value = true
  }
  
  const changedContactFilters = ref(false);
  const isModalVisible = ref(false);
  const selectedContacts = ref([]);
  const selectAll = ref(false);
  const selectAllOnPage = ref(false);
  const clearedSelectedContacts = ref(false);
  const clearedSelectedContactsOnPage = ref(false);
  const awaitingSearch = ref(false);
  const userPermissions = shallowReactive({
    isManagement: null,
    isHourly: null,
    isStudent: null,
    isResearch: null,
    isAdvancedResearch: null,
    isSuperuser: null,
    isAccountManager: null,
    isAccountStaff: null,
    isDev: null,
    isResearchLead: null,
    isAllContacts: null,
    isGenerateTimecard: null,
  });
  
  const pageSizeOptions = ref([
      {value: "clear", label: "----"},
      {value: 10, label: "10"},
      {value: 25, label: "25"},
      {value: 50, label: "50"},
      {value: 100, label: "100"},
      {value: 200, label: "200"},
    ])

  const selectedContactId = ref(null);

  const detailType = ref('company_view');

  const setDetailType = (detail) => {
    detailType.value = detail
  }
    
    // const pageConfigs = ref([])
    // const pageConfigOptions = ref([{value: null, label: '----'}])
    const selectedPageConfig = computed(() => {
        return store.state.pageConfigs.selectedPageConfig
    })

  
  watch(() => contactFilters, () => {
        if (!awaitingSearch.value) {
          setTimeout(() => {
            loadContacts();
            awaitingSearch.value = false;
          }, 2500); // 2.5 sec delay
        }
        awaitingSearch.value = true;
      },
      {deep: true}
  );

  // watch(() => store.state.pageConfigs.selectedPageConfig, () => {
  //   if(selectedPageConfig.value){
  //       pageSizeFilter.contactPageSizeFilter = selectedPageConfig.value.page_size
  //   }
  // })
  

  
  const totalPages = ref(0)
  
  const showFilterPane = computed(() => store.getters['contact/showFilterPane'])
  const showBulkOperationsPane = computed(() => store.getters['contact/showBulkOperationsPane'])
  const showCreateContactForm = computed(() => store.getters['contact/showCreateContactForm'])

  function selectContactRow(contactRowId) {
    selectedContactId.value = contactRowId
  }
  
  async function loadContacts(endpoint = fetchFilter.value, retainSpot) {
    isLoading.value = true;
    let payload = {
      token: store.state.auth.token,
      endpoint: retainSpot ? endpoint + `&page=${page.value}` : endpoint 
    }
    const result = await getContacts(payload);
    contactResults.value = result.contacts;
    next.value = result.next ? `&page=${result.page + 1}` : null
    prev.value = result.prev ? `&page=${result.page - 1}` : null
    count.value = result.count
    page.value = result.page
    pageSize.value = result.contacts.length
    totalPages.value = result.num_pages
    isLoading.value = false;
    resetSelectedContactsOnChange()
  
  }
  
  function showModal() {
      isModalVisible.value = true;
  }
    
  function closeCreateContactForm() {
    store.dispatch('contact/setShowCreateContactFormFalse')
  }
  function closeAddMultipleContactsForm() {
    showCreateMultipleContactsForm.value = false
  }
  
  function closeModal() {
    isModalVisible.value = false;
  }
  
  function resetSelectAllClearSelectedContacts() {
    resetSelectAll()
    clearSelectedContacts()
  }
  
  function setChangedContactFilters(){
    changedContactFilters.value = true;
  }
  
  function resetSelectedContactsOnChange(){
    if(changedContactFilters.value){
      changedContactFilters.value = false;
      resetSelectAllClearSelectedContacts()
    }
  
  }
  
  function clearSelectedContacts() {
    selectedContacts.value = [];
    clearedSelectedContacts.value = true;
  }
  
  function resetClearedSelectedContacts() {
    clearedSelectedContacts.value = false;
  }
  
  function clearSelectedContactsOnPage() {
    selectedContacts.value = selectedContacts.value.filter(contact => selectedContactsOnPage.value.indexOf(contact.id) === -1);
    // console.log('clear selected contacts on page')
    clearedSelectedContactsOnPage.value = true;
  }
  
  function resetClearedSelectedContactsOnPage() {
    clearedSelectedContactsOnPage.value = true;
  }
  
  function toggleSelectAllOnPage() {
    clearSelectedContactsOnPage()
    resetClearedSelectedContactsOnPage()
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }else{
      selectAllOnPage.value = true;
    }
  }
  
  function resetSelectAll() {
    if(selectAll.value){
      selectAll.value = false;
    }
    if(selectAllOnPage.value){
      selectAllOnPage.value = false;
    }
  }

  function getNext() {
    if (next.value) {
      loadContacts(fetchFilter.value + next.value)
      resetSelectAllClearSelectedContacts()
    } else {
      alert("No next page")
    }
  }
  
  function getPrev() {
    if (prev.value) {
      loadContacts(fetchFilter.value + prev.value)
      resetSelectAllClearSelectedContacts()
    } else {
      alert("No prev page")
    }
  }

  async function getCSV(){
    const token = store.state.auth.token
    showPopover("Export default CSV fields")
    await exportToCSV(fetchFilter.value, token)
    hidePopover()
  }

  async function getCSVWithCurrentFields(){
    const token = store.state.auth.token
    showPopover("Export contact fields on page")
    await exportToCSV(fetchFilter.value, token, selectedPageConfig.value.cells)
    hidePopover()
  }
  
  const selectedContactIds = computed(function() {
    if(selectedContacts.value.length > 0){
      return selectedContacts.value.map(({id}) => {
        return id
      })
  
    } else {
      return []
    }
  })

  const selectedContact = computed(function() {
    if(selectedContactId.value){
      return contactResults.value.find(contact => contact.id ===selectedContactId.value)
    } else {
      return null
    }
  })
  
  const contactResultsIds = computed(function() {
    if(contactResults.value.length > 0){
      return contactResults.value.map(({id}) => {
        return id
      })
    } else {
      return []
    }
  })
  
  const numSelectedContacts = computed(function() {
    return selectedContacts.value.length
  })
  
  const selectedContactsOnPage = computed(function() {
    if(numSelectedContacts.value > 0){
      return selectedContactIds.value.filter((contactId) => {
        return contactResultsIds.value.includes(contactId)
    })
    } else {
      return []
    }
  })
  
  const selectedContactsOnPageMap = computed(function() {
    return new Map(selectedContactsOnPage.value.map((contactId) => [contactId, contactId]))
  })
  
  const numSelectedContactsOnPage = computed(function() {
    return selectedContactsOnPage.value.length
    
  })
  
  const selectedContactsOffPage = computed(function(){
    return selectedContactIds.value.filter((contactId) => {
      return !contactResultsIds.value.includes(contactId)
    })
  })
  
  const numSelectedContactsOffPage = computed(function() {
    return selectedContactsOffPage.value.length
  })
  
  const viewingFromNbr = computed(function () {
    return (page.value * pageSizeFilter.pageSizeFilter + 1) - pageSizeFilter.pageSizeFilter
  })
  
  
  const viewingToNbr = computed(function () {
    if (page.value === totalPages.value) {
      return count.value;
    } else {
      return page.value * pageSize.value
    }
  })

  const isPopoverVisible = ref(false)
  const xPosition = ref(0)
  const yPosition = ref(0)
  const csvElement = ref(null)
  const exportCSVText = ref("")

  function showPopover(popoverText) {
      exportCSVText.value = popoverText
      isPopoverVisible.value = true;
      xPosition.value = csvElement.value.getBoundingClientRect().right
      yPosition.value = csvElement.value.getBoundingClientRect().top
    }

  function hidePopover() {
      isPopoverVisible.value = false;
      xPosition.value = 0;
      yPosition.value = 0;
    }

  
  const setUserPermissions = function () {
    let userPermissionsStore = store.getters['auth/showUserPermissions']
    userPermissions.isManagement = userPermissionsStore.isManagement
    userPermissions.isHourly = userPermissionsStore.isHourly
    userPermissions.isStudent =  userPermissionsStore.isStudent
    userPermissions.isResearch =  userPermissionsStore.isResearch
    userPermissions.isAdvancedResearch =  userPermissionsStore.isAdvancedResearch
    userPermissions.isSuperuser =  userPermissionsStore.isSuperuser
    userPermissions.isAccountManager =  userPermissionsStore.isAccountManager
    userPermissions.isAccountStaff =  userPermissionsStore.isAccountStaff
    userPermissions.isDev =  userPermissionsStore.isDev
    userPermissions.isResearchLead =  userPermissionsStore.isResearchLead
    userPermissions.isAllContacts =  userPermissionsStore.isAllContacts
    userPermissions.isGenerateTimecard =  userPermissionsStore.isGenerateTimecard
  }
  
  function addSelectedContact(contact) {
    let foundIndex = selectedContactIds.value.indexOf(contact.id);
    if (foundIndex === -1) {
      selectedContacts.value.push(contact)
    }
  }
  
  function removeSelectedContact(contact) {
    let foundIndex = selectedContactIds.value.indexOf(contact.id);
    if (foundIndex !== -1) {
      selectedContacts.value.splice(foundIndex, 1)
    }
  }
  
  function addRemoveSelectedContacts(contact) {
    let foundIndex = selectedContacts.value.indexOf(contact);
    if (foundIndex === -1) {
      selectedContacts.value.push(contact)
    } else {
        selectedContacts.value.splice(foundIndex, 1)
      }
    }
  
  function setContactFilters(updatedFilters) {
    contactFilters.contactIdFilter = updatedFilters.value.contactIdFilter
    contactFilters.contactFirstNameFilter = updatedFilters.value.contactFirstNameFilter
    contactFilters.contactLastNameFilter = updatedFilters.value.contactLastNameFilter
    contactFilters.contactFullNameFilter = updatedFilters.value.contactFullNameFilter
    contactFilters.contactLinkedInFilter = updatedFilters.value.contactLinkedInFilter
    contactFilters.contactEmailFilter = updatedFilters.value.contactEmailFilter
    contactFilters.contactPhoneFilter = updatedFilters.value.contactPhoneFilter
    contactFilters.contactTitleFilter = updatedFilters.value.contactTitleFilter
    contactFilters.contactPersonalNotesFilter = updatedFilters.value.contactPersonalNotesFilter
    contactFilters.contactContactNotesFilter = updatedFilters.value.contactContactNotesFilter
    contactFilters.contactAllNotesFilter = updatedFilters.value.contactAllNotesFilter
    contactFilters.contactCompanyNameFilter = updatedFilters.value.contactCompanyNameFilter
    contactFilters.contactCompanyIdFilter = updatedFilters.value.contactCompanyIdFilter
    contactFilters.contactCompanyWebsiteUrlFilter = updatedFilters.value.contactCompanyWebsiteUrlFilter
    contactFilters.contactCompanyAddressFilter = updatedFilters.value.contactCompanyAddressFilter
    contactFilters.contactCompanyCampaignFilter = updatedFilters.value.contactCompanyCampaignFilter
    contactFilters.contactCompanyMinRevenueFilter = updatedFilters.value.contactCompanyMinRevenueFilter
    contactFilters.contactCompanyMaxRevenueFilter = updatedFilters.value.contactCompanyMaxRevenueFilter
    contactFilters.contactCompanyMinHeadcountFilter = updatedFilters.value.contactCompanyMinHeadcountFilter
    contactFilters.contactCompanyMaxHeadcountFilter = updatedFilters.value.contactCompanyMaxHeadcountFilter
    contactFilters.contactCompanyMinRevenueZiCombinedFilter = updatedFilters.value.contactCompanyMinRevenueZiCombinedFilter
    contactFilters.contactCompanyMaxRevenueZiCombinedFilter = updatedFilters.value.contactCompanyMaxRevenueZiCombinedFilter
    contactFilters.contactCompanyAccountManagerResearchNotesFilter = updatedFilters.value.contactCompanyAccountManagerResearchNotesFilter
  }

  
  function setContactPageSizeFilter(target) {
      target == "clear" ? pageSizeFilter.contactPageSizeFilter = null : pageSizeFilter.contactPageSizeFilter = target
      resetSelectAllClearSelectedContacts()
      loadContacts()
  }
  
  
  const fetchFilter = computed(function () {
    let query = new URLSearchParams({
    });
    if (contactFilters.contactIdFilter) {
      query.append("id_number", contactFilters.contactIdFilter);
    }
    if (contactFilters.contactFirstNameFilter) {
      query.append("first_name", contactFilters.contactFirstNameFilter);
    }
    if (contactFilters.contactLastNameFilter) {
      query.append("last_name", contactFilters.contactLastNameFilter);
    }
    if (contactFilters.contactFullNameFilter) {
      query.append("full_name", contactFilters.contactFullNameFilter);
    }
    if (contactFilters.contactLinkedInFilter) {
      query.append("linkedin", contactFilters.contactLinkedInFilter);
    }
    if (contactFilters.contactEmailFilter) {
      query.append("email", contactFilters.contactEmailFilter);
    }
    if (contactFilters.contactPhoneFilter) {
      query.append("phone", contactFilters.contactPhoneFilter);
    }
    if (contactFilters.contactTitleFilter) {
      query.append("title", contactFilters.contactTitleFilter);
    }
    if (contactFilters.contactPersonalNotesFilter) {
      query.append("personal_notes", contactFilters.contactPersonalNotesFilter);
    }
    if (contactFilters.contactContactNotesFilter) {
      query.append("contact_notes", contactFilters.contactContactNotesFilter);
    }
    if (contactFilters.contactAllNotesFilter) {
      query.append("all_notes", contactFilters.contactAllNotesFilter);
    }
    if (contactFilters.contactCompanyNameFilter) {
      query.append("company_name", contactFilters.contactCompanyNameFilter);
    }
    if (contactFilters.contactCompanyIdFilter) {
      query.append("company_id_number", contactFilters.contactCompanyIdFilter);
    }
    if (contactFilters.contactCompanyWebsiteUrlFilter) {
      query.append("company_url", contactFilters.contactCompanyWebsiteUrlFilter);
    }
    if (contactFilters.contactCompanyAddressFilter) {
      query.append("company_address", contactFilters.contactCompanyAddressFilter);
    }
    if (contactFilters.contactCompanyCampaignFilter) {
      if(Array.isArray(contactFilters.contactCompanyCampaignFilter)){
        contactFilters.contactCompanyCampaignFilter.forEach(campaign => {
          query.append("company_campaign", campaign)
        })
      } else {
        query.append("company_campaign", contactFilters.contactCompanyCampaignFilter)
      }
    }
    if (contactFilters.contactCompanyMinRevenueFilter) {
      query.append("company_min_revenue", contactFilters.contactCompanyMinRevenueFilter);
    }
    if (contactFilters.contactCompanyMaxRevenueFilter) {
      query.append("company_max_revenue", contactFilters.contactCompanyMaxRevenueFilter);
    }
    if (contactFilters.contactCompanyMinHeadcountFilter) {
      query.append("company_min_headcount", contactFilters.contactCompanyMinHeadcountFilter);
    }
    if (contactFilters.contactCompanyMaxHeadcountFilter) {
      query.append("company_max_headcount", contactFilters.contactCompanyMaxHeadcountFilter);
    }
    if (contactFilters.contactCompanyMinRevenueZiCombinedFilter) {
      query.append("company_min_revenue_combined", contactFilters.contactCompanyMinRevenueZiCombinedFilter);
    }
    if (contactFilters.contactCompanyMaxRevenueZiCombinedFilter) {
      query.append("company_max_revenue_combined", contactFilters.contactCompanyMaxRevenueZiCombinedFilter);
    }
    if (pageSizeFilter.contactPageSizeFilter) {
      query.append("page_size", pageSizeFilter.contactPageSizeFilter); // To append more data
    }
    if (contactFilters.contactCompanyOutreachNotesFilter) {
      query.append("company_outreach_notes", contactFilters.contactCompanyOutreachNotesFilter);
    }
    if (contactFilters.contactCompanyAccountManagerResearchNotesFilter) {
      query.append("company_account_manager_research_notes", contactFilters.contactCompanyAccountManagerResearchNotesFilter);
    }
  
  // (B) CONVERT TO STRING, APPEND TO URL
    let url;
    let queriesCount = 0;
    for (let value of query.values()) {
      queriesCount = queriesCount + 1;
    }
    if (queriesCount > 0) {
      url = "?" + query.toString();
    } else {
      url = "?";
    }
    return url
  })
  
  function jumpToPage(targetPage) {
    loadContacts(fetchFilter.value + `&page=${targetPage}`)
    resetSelectAllClearSelectedContacts()
  }
  
  function goToFirst(){
    loadContacts(fetchFilter.value + `&page=${1}`)
    resetSelectAllClearSelectedContacts()
  }
  
  function goToLast(){
    loadContacts(fetchFilter.value + `&page=${totalPages.value}`)
    resetSelectAllClearSelectedContacts()
  }
  
  setUserPermissions()
  loadContacts()

  // closeCreateContactForm()
  </script>
  
  <style scoped>
  </style>
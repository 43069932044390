<template>
    <div class="h-full flex flex-col justify-center items-center w-1/3">
        <div class="text-dark-primary text-wider text-2xl font-light border-b border-gray-300 w-full flex justify-center pb-2">
            CRM Reporting Dashboard
        </div>
        <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
            Client
        </div>
        <type-ahead-box
            placeholder="Choose Client"
            nullLabel="No Results"
            :default-value="client"
            :options="clientOptions"
            @update-value="updateClient"
        ></type-ahead-box>
        <div class="text-sm flex items-center w-full pl-2 pb-1 pt-4 font-semibold tracking-widest text-gray-600">
            Campaigns
        </div>
        <type-ahead-multiselect
            v-if="client"
            placeholder="Choose Campaigns"
            nullLabel="No Results"
            :default-value="campaigns"
            :options="campaignOptions"
            @update-value="updateCampaigns"
        ></type-ahead-multiselect>
        <div v-else
            class="border text-gray-600 bg-gray-200 border-gray-300 p-1.5 col-span-3 w-full"
        >
            Select Client To Filter Campaigns
        </div>
        <div class="flex justify-center items-center pt-4">
            <button class="bg-dark-secondary hover:bg-dark-primary hover:text-gray-100 hover:shadow active:bg-dark-primary active:text-gray-400 text-white tracking-wider font-medium px-3 py-1.5 rounded-sm" @click="viewReport">View Client Report</button>
        </div>
    </div>
</template>

<script setup> 
import { ref, defineProps, defineEmits, watch } from 'vue';

const props = defineProps(['clientOptions', 'campaignOptions', 'selectedClient', 'selectedCampaigns'])
const emit = defineEmits(['select-client', 'select-campaigns'])

const client = ref(props.selectedClient)

const campaigns = ref(props.selectedCampaigns)


watch(() => client.value, () => {
    if(client.value) {
        emit('select-client', client.value)
    }
})

watch(() => campaigns.value, () => {
    if(campaigns.value) {
        emit('select-campaigns', campaigns.value)
    }
})

watch(() => props.selectedCampaigns, () => {
    campaigns.value = props.selectedCampaigns
})

function updateClient(clientId) {
    client.value = clientId
}

function updateCampaigns(campaignIds) {
    campaigns.value = campaignIds
}
// function handleInputChange(e) {
//     emit('select-client', e.target.value)
// }

</script>